import { motion } from 'framer-motion'
import React from 'react'
import { Container } from 'react-bootstrap'
import Questionaire from '../components/forms/Questionaire'

const QuestionaireScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100vw',
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: '100vw',
      scale: 1.2,
    },
  }
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container className='my-5'>
        <h3>Questionnaire</h3>
        <Questionaire />
      </Container>
    </motion.div>
  )
}

export default QuestionaireScreen
