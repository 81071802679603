import { motion } from 'framer-motion'
import React from 'react'
import { useInView } from 'react-intersection-observer'

const FromSides = ({ children }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
    triggerOnce: false,
  })
  const variants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: 'spring', duration: 1, bounce: 0.3 },
    },
    hidden: {
      opacity: 0,
      x: -1100,
    },
  }
  return (
    <motion.div
      ref={ref}
      animate={inView ? 'visible' : 'hidden'}
      transition={{ duration: 0.8, ease: 'easeIn' }}
      variants={variants}
    >
      {children}
    </motion.div>
  )
}

export default FromSides
