import { motion } from 'framer-motion'
import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import projects from '../data/projects'

const ProjectsScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100vw',
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: '100vw',
      scale: 1.2,
    },
  }
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container className='my-5'>
        <Row>
          {projects.map((project) => (
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              key={project.id}
              className='mb-5'
            >
              <LinkContainer to='/projectdetails' style={{ cursor: 'pointer' }}>
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12} className='px-0'>
                    <Image src={project.thumbnail} className='w-100' />
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className='hover-effect d-flex align-items-center '
                  >
                    <motion.div whileHover={{ y: -10 }}>
                      <div className='projects-into'>
                        <h5>{project.title}</h5>
                        <p>{project.desc}</p>
                        <p>What We Did</p>
                        <p>{project.desc2}</p>
                      </div>
                    </motion.div>
                  </Col>
                </Row>
              </LinkContainer>
            </Col>
          ))}
        </Row>
      </Container>
    </motion.div>
  )
}

export default ProjectsScreen
