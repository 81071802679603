import { motion } from 'framer-motion'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FadeInWhenVisible from '../utils/FadeInWhenVisible'

const ServicesScreen = () => {
  return (
    <Container>
      <Row className='margin'>
        <Col
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className='d-flex align-items-center'
        >
          <FadeInWhenVisible xAxis='-400px'>
            <div className='wrapper'>
              <h2 className='yellow'>Creative</h2>
              <p className='yellow'>Services</p>
            </div>
          </FadeInWhenVisible>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <FadeInWhenVisible xAxis='400px'>
            <div>
              <p>
                We create new brands and revitalize existing ones. We make sure
                to dig deep in the essence of your brand to help you stir it and
                convey it in a unique and memorable way.
              </p>
              <p className='my-5' style={{ textTransform: 'uppercase' }}>
                Our creative services include
                <br /> Branding <br />
                Design
                <br /> Advertising <br />
                Media planning
              </p>
              <p>
                Fill out the brand questionnaire below to see how we can help
                improve your brand
              </p>
              <motion.div whileHover={{ x: 10 }} whileTap={{ scale: 0.9 }}>
                <Button
                  variant='outlineGold'
                  as={Link}
                  to='/questionnaire'
                  style={{ color: 'black' }}
                >
                  Learn More
                </Button>
              </motion.div>
            </div>
          </FadeInWhenVisible>
        </Col>
      </Row>
      <Row className='margin'>
        <Col
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className='d-flex align-items-center'
        >
          <FadeInWhenVisible xAxis='-400px'>
            <div className='wrapper'>
              <h2 className='pink'>
                Social
                <br /> Media
              </h2>
              <p className='pink'>Services</p>
            </div>
          </FadeInWhenVisible>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <FadeInWhenVisible xAxis='400px'>
            <p>
              Let’s get your brand buzzing! We connect people to brands. We make
              sure you reach the right audience through strategic planning and
              campaigns that stand out.
            </p>
            <p className='my-5' style={{ textTransform: 'uppercase' }}>
              Our social media services include
              <br /> Account Management <br />
              STRATEGIES
              <br /> CONTENT CREATION <br />
              ADS & Promoted Content <br /> Media Planning <br /> Copywriting
            </p>
            <p>
              Fill out the brand questionnaire below to see how we can help
              improve your brand
            </p>
            <motion.div whileHover={{ x: 10 }} whileTap={{ scale: 0.9 }}>
              <Button
                variant='outlinePink'
                as={Link}
                to='/questionnaire'
                style={{ color: 'black' }}
              >
                Learn More
              </Button>
            </motion.div>
          </FadeInWhenVisible>
        </Col>
      </Row>

      <Row className='margin'>
        <Col
          lg={6}
          md={6}
          sm={12}
          xs={12}
          className='d-flex align-items-center'
        >
          <FadeInWhenVisible xAxis='-400px'>
            <div className='wrapper'>
              <h2 className='blue'>
                Digital
                <br /> Solutions
              </h2>
              <p className='blue'>Services</p>
            </div>
          </FadeInWhenVisible>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <FadeInWhenVisible xAxis='400px'>
            <p>
              Let’s get your brand buzzing! We connect people to brands. We make
              sure you reach the right audience through strategic planning and
              campaigns that stand out.
            </p>
            <p className='my-5' style={{ textTransform: 'uppercase' }}>
              Our mocial media services include
              <br /> Account Management <br />
              STRATEGIES
              <br /> CONTENT CREATION <br />
              ADS & Promoted Content <br /> Media Planning <br /> Copywriting
            </p>
            <p>
              Fill out the brand questionnaire below to see how we can help
              improve your brand
            </p>
            <motion.div whileHover={{ x: 10 }} whileTap={{ scale: 0.9 }}>
              <Button
                variant='outlineBlue'
                as={Link}
                to='/questionnaire'
                style={{ color: 'black' }}
              >
                Learn More
              </Button>
            </motion.div>
          </FadeInWhenVisible>
        </Col>
      </Row>
    </Container>
  )
}

export default ServicesScreen
