const projects = [
  {
    id: '0',
    thumbnail: '/images/clients/juthoor.png',
    title: 'JUTHOOR',
    desc: 'Body and mind wellness spa',
    desc2: 'We created a unique identity focusing on the roots of wellbeing.',
  },
  {
    id: '1',
    thumbnail: '/images/clients/mounetna.png',
    title: 'MOUNETNA',
    desc: 'Premium line of food products',
    desc2:
      'Focusing on authenticity, we created a brand that reflects pure Lebanese values and tradition.',
  },
  {
    id: '2',
    thumbnail: '/images/clients/juthoor.png',
    title: 'JUTHOOR',
    desc: 'Body and mind wellness spa',
    desc2: 'We created a unique identity focusing on the roots of wellbeing.',
  },
  {
    id: '3',
    thumbnail: '/images/clients/mounetna.png',
    title: 'MOUNETNA',
    desc: 'Premium line of food products',
    desc2:
      'Focusing on authenticity, we created a brand that reflects pure Lebanese values and tradition.',
  },
  {
    id: '4',
    thumbnail: '/images/clients/juthoor.png',
    title: 'JUTHOOR',
    desc: 'Body and mind wellness spa',
    desc2: 'We created a unique identity focusing on the roots of wellbeing.',
  },
  {
    id: '5',
    thumbnail: '/images/clients/mounetna.png',
    title: 'MOUNETNA',
    desc: 'Premium line of food products',
    desc2:
      'Focusing on authenticity, we created a brand that reflects pure Lebanese values and tradition.',
  },
]
export default projects
