import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Image1 from '../images/contact/qatar.png'
import Image2 from '../images/contact/leb.png'
import ContactForm from '../components/forms/ContactForm'
import WorkForm from '../components/forms/WorkForm'
import { motion } from 'framer-motion'
import FadeInWhenVisible from '../utils/FadeInWhenVisible'
import FromSides from '../utils/FromSides'

const ContactScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100vw',
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: '100vw',
      scale: 1.2,
    },
  }
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container fluid className='px-0'>
        <FadeInWhenVisible>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Image className='d-block w-100' src={Image1} alt='First slide' />
            </Col>
          </Row>
          <Row className='d-flex justify-content-center align-items-center my-5'>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className='d-flex justify-content-center align-items-center'
            >
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  type: 'spring',
                  damping: 20,
                  delay: 0.8,
                }}
              >
                <h3>Qatar</h3>
              </motion.div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}>
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  type: 'spring',
                  damping: 20,
                  delay: 0.8,
                }}
              >
                <p>
                  Level 15, Commercial Bank Tower, West Bay, Doha, Qatar.
                  P.O.BOX
                </p>
                <p>+974 50006456</p>
                <p>info@mediaexpress.agency</p>
              </motion.div>
            </Col>
          </Row>
        </FadeInWhenVisible>
      </Container>
      <Container fluid className='px-0'>
        <FadeInWhenVisible>
          <Row>
            <Col lg={12} md={12} sm={12} xs={12}>
              <Image className='d-block w-100' src={Image2} alt='First slide' />
            </Col>
          </Row>
          <Row className='d-flex justify-content-center align-items-center my-5'>
            <Col
              lg={2}
              md={2}
              sm={2}
              xs={2}
              className='d-flex justify-content-center align-items-center'
            >
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  type: 'spring',
                  damping: 20,
                  delay: 0.8,
                }}
              >
                <h3>Lebanon</h3>
              </motion.div>
            </Col>
            <Col lg={2} md={2} sm={2} xs={2}>
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  type: 'spring',
                  damping: 20,
                  delay: 0.8,
                }}
              >
                <p>Rabieh main road, Lebanon, Antelias</p>
                <p>+961 3159127</p>
                <p>info@mediaexpress.agency</p>
              </motion.div>
            </Col>
          </Row>
        </FadeInWhenVisible>
      </Container>
      <Container>
        <FromSides>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <h2 className='purple' style={{ fontSize: '4rem' }}>
                Get in touch
              </h2>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  type: 'spring',
                  damping: 20,
                  delay: 0.8,
                }}
              >
                <h2 className='yellow' style={{ fontSize: '4rem' }}>
                  Work with us
                </h2>
              </motion.div>
            </Col>
          </Row>
          <Row className='my-5'>
            <Col lg={6} md={6} sm={12} xs={12}>
              <ContactForm />
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <WorkForm />
            </Col>
          </Row>
        </FromSides>
      </Container>
    </motion.div>
  )
}

export default ContactScreen
