import { motion } from 'framer-motion'
import React from 'react'
import { useInView } from 'react-intersection-observer'

const FromOtherSide = ({ children }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: false,
  })
  const variants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: {
      opacity: 0,
      scale: 0.65,
      y: 50,
    },
  }
  return (
    <motion.div
      ref={ref}
      animate={inView ? 'visible' : 'hidden'}
      variants={variants}
      transition={{ duration: 2, ease: 'easeOut' }}
    >
      {children}
    </motion.div>
  )
}

export default FromOtherSide
