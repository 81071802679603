import { motion } from 'framer-motion'
import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Image1 from '../images/aboutus/About-us.png'
const AboutScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100vw',
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: '100vw',
      scale: 1.2,
    },
  }
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container fluid className='px-0 about'>
        <Image src={Image1} width='100%' />
        <div className='about-header'>
          <motion.div
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 20,
              delay: 0.8,
            }}
          >
            <p>
              We are a creative agency specialized in advertising and digital
              creation
              <br /> working with companies that dare to be different!
            </p>
          </motion.div>
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 20,
              delay: 1.4,
            }}
          >
            <h1 className='pink'>
              Unstoppable
              <br /> Creations
            </h1>
          </motion.div>
          <motion.div
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 20,
              delay: 2,
            }}
          >
            <p>
              We are a team of dedicated visual designers with shared experience
              in the world of
              <br /> branding, digital design, social media management.
            </p>
          </motion.div>
        </div>
      </Container>
      <Container className='my-5'>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className='text-center'>
            <h2 className='yellow'>Clients</h2>
          </Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </Container>
    </motion.div>
  )
}

export default AboutScreen
