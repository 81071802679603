import { motion } from 'framer-motion'
import React from 'react'
import { Button, Carousel, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Image1 from '../images/landing-page/Img_Creative.png'
import Image2 from '../images/landing-page/Img_Digital.png'
import Image3 from '../images/landing-page/img_Social.png'
import './Main.css'

const CarouselHome = () => {
  return (
    <Carousel fade>
      <Carousel.Item>
        <Image className='d-block w-100' src={Image1} alt='First slide' />
        <Carousel.Caption>
          <div className='content' style={{ WebkitTextStroke: '2px #ffae46' }}>
            <h1 className='yellow'>Creative</h1>
            <h1 className='yellow'>Creative</h1>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, ease: 'easeIn', delay: 1 }}
          >
            <p>
              We create new brands and revitalize existing ones.
              <br /> We make sure to dig deep in the essence of <br /> your
              brand to help you stir it and convey it in a <br />
              unique and memorable way.
            </p>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
              <Button variant='outlineGold' as={Link} to='/'>
                Learn More
              </Button>
            </motion.div>
          </motion.div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Image className='d-block w-100' src={Image3} alt='Second slide' />
        <Carousel.Caption>
          <div className='content' style={{ WebkitTextStroke: '2px #f62c84' }}>
            <h1 className='pink'>Social</h1>
            <h1 className='pink'>Social</h1>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, ease: 'easeIn', delay: 1 }}
          >
            <p>
              We connect people to brands. <br /> We make sure you reach the
              right audience through <br /> strategic planning and campaigns
              that standout.
            </p>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
              <Button variant='outlinePink' as={Link} to='/'>
                Learn More
              </Button>
            </motion.div>
          </motion.div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Image className='d-block w-100' src={Image2} alt='Third slide' />
        <Carousel.Caption>
          <div className='content' style={{ WebkitTextStroke: '2px #0071bc' }}>
            <h1 className='blue'>Digital</h1>
            <h1 className='blue'>Digital</h1>
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, ease: 'easeIn', delay: 1 }}
          >
            <p>
              We design and develop innovative responsive websites
              <br /> and native apps for iOs and Android devices to get you{' '}
              <br />
              up to speed with the constantly evolving digital world!
            </p>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
              <Button variant='outlineBlue' as={Link} to='/'>
                Learn More
              </Button>
            </motion.div>
          </motion.div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}

export default CarouselHome
