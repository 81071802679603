import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CarouselHome from '../components/CarouselHome'
import OurWork from '../images/landing-page/our-work.png'
import { motion } from 'framer-motion'
import FromSides from '../utils/FromSides'
import FromOtherSide from '../utils/FromOtherSide'
import FadeInWhenVisible from '../utils/FadeInWhenVisible'

const HomeScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100vw',
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      x: '100vw',
      scale: 1.2,
    },
  }
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }

  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container fluid className='px-0'>
        <CarouselHome />
      </Container>

      <Container className='my-5'>
        <Row className='py-5'>
          <Col lg={12} md={12} xm={12} sm={12} className='text-center'>
            <FromSides xAxis='-1100px'>
              <h1 className='pink'>About us</h1>
            </FromSides>
            <FromOtherSide>
              <h4 className='pt-5'>
                We are a creative agency specialized in advertising and digital
                creation
                <br /> working with companies that dare to be different!
              </h4>
            </FromOtherSide>
          </Col>
        </Row>
        <Row className='py-5'>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div>
              <h5>Mission</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
              </p>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div>
              <h5>Vision</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} xm={12} sm={12} className='text-center'>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
              <Button
                variant='outlinePink'
                as={Link}
                to='/about'
                style={{ color: 'black' }}
              >
                Learn More
              </Button>
            </motion.div>
          </Col>
        </Row>
        <Row className='py-5'>
          <Col lg={6} md={6} sm={12} xs={12}>
            <FadeInWhenVisible>
              <h1 className='purple'>our work</h1>
            </FadeInWhenVisible>
            <p>
              The Client
              <br /> Cedar Bakery.
            </p>
            <p>
              The Challenge
              <br /> Increase engagement on Instagram and reach the local
              community.
            </p>
            <p>
              What We Did
              <br /> We created a unique style and focused on targeting the
              local communities with more engaging and product oriented posts.
            </p>
            <p>
              The Result
              <br /> Page visits increase of 180% and still growing. More visits
              from residents to the shop which led directly to increase in
              sales.
            </p>
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className='px-0 d-flex align-items-center'
          >
            <Image src={OurWork} className='w-100' />
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} xm={12} sm={12} className='text-center'>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
              <Button
                variant='outlinePurple'
                as={Link}
                to='/projects'
                style={{ color: 'black' }}
              >
                Learn More
              </Button>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </motion.div>
  )
}

export default HomeScreen
