import React from 'react'
import { Button, Col, Form, FormGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import './Forms.css'
import { motion } from 'framer-motion'

const Questionaire = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = (data, e) => {
    console.log(watch())
    e.target.reset()
  }
  return (
    <Form className='contact-form pr-5' onSubmit={handleSubmit(onSubmit)}>
      <Form.Row>
        <FormGroup as={Col}>
          <Form.Label>What is your company Name?</Form.Label>
          <Form.Control
            {...register('cname', { required: true })}
            type='text'
          />
        </FormGroup>
      </Form.Row>
      {errors.cname && <p>All fields with an asterisk (*) are required</p>}
      <Form.Row>
        <FormGroup as={Col}>
          <Form.Label>
            What services or products does you company provide?
          </Form.Label>
          <Form.Control
            {...register('sname', { required: true })}
            type='number'
          />
        </FormGroup>
      </Form.Row>
      {errors.sname && <p>All fields with an asterisk (*) are required</p>}
      <Form.Row>
        <FormGroup as={Col}>
          <Form.Label>Who are your customers? (Target Audience)</Form.Label>
          <Form.Control
            {...register('customer', {
              required: true,
            })}
            type='text'
          />
        </FormGroup>
      </Form.Row>
      {errors.customer && <p>All fields with an asterisk (*) are required</p>}
      <Form.Row>
        <FormGroup as={Col}>
          <Form.Label>
            What is your brand story & when was it established?
          </Form.Label>
          <Form.Control
            {...register('story', {
              required: true,
            })}
            type='text'
          />
        </FormGroup>
      </Form.Row>
      {errors.story && <p>All fields with an asterisk (*) are required</p>}
      <Form.Row>
        <FormGroup as={Col}>
          <Form.Label>
            What adjectives would you use to describe your brand? (Minimal,
            Smart, Colorful…)
          </Form.Label>
          <Form.Control
            {...register('adjectives', {
              required: true,
            })}
            type='text'
          />
        </FormGroup>
      </Form.Row>
      {errors.adjectives && <p>All fields with an asterisk (*) are required</p>}
      <Form.Row>
        <FormGroup as={Col}>
          <Form.Label>Who are your competitors?</Form.Label>
          <Form.Control
            {...register('competitors', {
              required: true,
            })}
            type='text'
          />
        </FormGroup>
      </Form.Row>
      {errors.competitors && (
        <p>All fields with an asterisk (*) are required</p>
      )}
      <Form.Row>
        <FormGroup as={Col}>
          <Form.Label>Which logos inspire you?</Form.Label>
          <Form.Control
            {...register('logo', {
              required: true,
            })}
            type='text'
          />
        </FormGroup>
      </Form.Row>
      {errors.logo && <p>All fields with an asterisk (*) are required</p>}
      <Form.Row>
        <FormGroup as={Col}>
          <Form.Label>
            Which colors do you feel fit your brand the best?
          </Form.Label>
          <Form.Control
            {...register('color', {
              required: true,
            })}
            type='text'
          />
        </FormGroup>
      </Form.Row>
      {errors.color && <p>All fields with an asterisk (*) are required</p>}
      <Form.Row>
        <FormGroup as={Col}>
          <Form.Label>
            How would you describe your business to a friend?
          </Form.Label>
          <Form.Control
            {...register('business', {
              required: true,
            })}
            type='text'
          />
        </FormGroup>
      </Form.Row>
      {errors.business && <p>All fields with an asterisk (*) are required</p>}
      <Form.Row>
        <FormGroup as={Col} controlId='formMessage'>
          <Form.Label>
            Any other comments or notes you would like to add?
          </Form.Label>
          <Form.Control
            {...register('message', { required: false })}
            as='textarea'
            rows={4}
            placeholder='Answer'
          />
        </FormGroup>
      </Form.Row>
      {errors.message && <p>All fields with an asterisk (*) are required</p>}
      <span>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
          <Button
            variant='outlinePurple'
            type='submit'
            style={{ color: 'black' }}
            className='w-100'
          >
            Submit
          </Button>
        </motion.div>
      </span>
    </Form>
  )
}

export default Questionaire
