import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

const FadeInWhenVisible = ({ children, xAxis }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({ threshold: 0.2 })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
    if (!inView) {
      controls.start('hidden')
    }
  }, [controls, inView])

  return (
    <AnimatePresence>
      <motion.div
        ref={ref}
        animate={controls}
        initial='hidden'
        exit='exit'
        transition={{ duration: 1 }}
        variants={{
          visible: {
            opacity: 1,
            x: 0,
            transition: { type: 'spring', duration: 1, bounce: 0.3 },
          },
          hidden: {
            opacity: 0,
            x: xAxis,
            transition: { type: 'spring', duration: 1, bounce: 0.3 },
          },
          exit: {
            opacity: 0,
            x: xAxis,
            transition: { type: 'spring', duration: 1, bounce: 0.3 },
          },
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default FadeInWhenVisible
