import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import HomeScreen from './screens/HomeScreen'
import Header from './components/nav/Header'
import AboutScreen from './screens/AboutScreen'
import ServicesScreen from './screens/ServicesScreen'
import ProjectsScreen from './screens/ProjectsScreen'
import ContactScreen from './screens/ContactScreen'
import DetailsScreen from './screens/DetailsScreen'
import Footer from './components/nav/Footer'
import ScrollToTop from './utils/ScrolltoTop'
import QuestionaireScreen from './screens/QuestionaireScreen'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <main style={{ overflowX: 'hidden' }}>
        <Container fluid className='px-0'>
          <Route path='/' component={HomeScreen} exact />
          <Route path='/about' component={AboutScreen} />
          <Route path='/services' component={ServicesScreen} />
          <Route path='/projects' component={ProjectsScreen} />
          <Route path='/contact' component={ContactScreen} />
          <Route path='/projectdetails' component={DetailsScreen} />
          <Route path='/questionnaire' component={QuestionaireScreen} />
        </Container>
      </main>
      <Footer />
    </Router>
  )
}

export default App
