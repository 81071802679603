import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import { LinkContainer } from 'react-router-bootstrap'

const Footer = () => {
  return (
    <footer>
      <Container className='py-5'>
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <ul>
              <li>
                <LinkContainer to='/'>
                  <span>HOME</span>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to='/services'>
                  <span>SERVICES</span>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to='/about'>
                  <span>ABOUT US</span>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to='/projects'>
                  <span>PROJECTS</span>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to='/contact'>
                  <span>CONTACT US</span>
                </LinkContainer>
              </li>
            </ul>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <ul>
              <li>
                <strong>contact us</strong>
              </li>
              <li>
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    QATAR
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={12}>
                    +974 50006456
                    <br /> +974 44528395
                  </Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    LEBANON
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={12}>
                    +974 50006456
                  </Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    EMAIL
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={12}>
                    info@mediaexpress.agency
                  </Col>
                </Row>
              </li>
            </ul>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <ul>
              <li>
                <strong>Follow us</strong>
              </li>
              <li>@mediaexpress.agency</li>
              <li className='social-icons'>
                <a href='/' target='_blank' rel='noreferrer' title='Facebook'>
                  <AiIcons.AiOutlineInstagram fontSize='26' />
                </a>
                <a href='/' target='_blank' rel='noreferrer' title='Facebook'>
                  <FaIcons.FaFacebookSquare fontSize='26' />
                </a>
                <a href='/' target='_blank' rel='noreferrer' title='Facebook'>
                  <FaIcons.FaLinkedin fontSize='26' />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
